
@import '../../../assets/scss/common/variables';
.restore-cart-modal {
  .modal-mask {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: auto;
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 100%;
    }
  }
  .modal-wrapper {
    display: block;
  }
}
.restore-cart-content {
  font-size: 13px;
  color: $C_BLUE
}
